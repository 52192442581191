import { App }  from 'vue';

import getRoute from '@/utils/routing';

import { RouteParams } from '../../../vendor/friendsofsymfony/jsrouting-bundle/Resources/js/router';

export default {
    install: (app: App) => {
        app.config.globalProperties.$backendRoutes = getRoute;
    },
};

declare module '@vue/runtime-core' {
    interface ComponentCustomProperties {
        $backendRoutes: (route: string, params?: RouteParams, absolute?: boolean) => string;
    }
}
